import { combineReducers } from "redux";
// import { routerReducer as routing } from "react-router-redux";
import { LOGIN, LOGOUT,UPDATE, SET } from "../types";

const authReducer = (state = { isAuthenticated: false, selection:'none'}, { selection, payload, type, sidebarUnfoldable }) => {
  switch (type) {
    case UPDATE:
      console.log("UPDATE STATE Selection "+selection);
      return { ...state, Selection: selection };
      //return { ...state, [payload.key]: payload.value };
    case LOGIN:
      console.log("LOGIN STATE Selection "+selection);
      return { ...state,
         isAuthenticated: true,
         UserData: payload,
         Selection: selection,
       };
    case LOGOUT:
      return { ...state, isAuthenticated: false };
     
    default:
      return state;
  }
};

export default combineReducers({
  auth: authReducer
  // routing
});

import React from "react";
import { Route, redirect, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";

// Containers
const DefaultLayout = React.lazy(() => import("../layout/DefaultLayout"))

const RequireAuth = ({ isAuthenticated }) =>
  !isAuthenticated ? (
    <Navigate replace to="/Login" />
  ) : (
    <Routes>
      <Route path="*" name="Home" element={<DefaultLayout />} />
    </Routes>
    
  );

export default connect(state => ({
  isAuthenticated: state.auth.isAuthenticated,
  UserData: state.auth.UserData,
  DevSelection: state.auth.DevSelection
}))(RequireAuth);
